import $amplitude from './amplitude'

/* eslint-disable camelcase */

/**
 * [Landing] Stories Try For Free Button Click
 * @param {string} source
 */

export const eventStoriesTryForFreeButtonClick = source => {
  $amplitude('[Landing] Stories Try For Free Button Click', {
    source,
  })
}

/**
 * [Landing] Stories Click
 * @param {string} source
 */

export const eventStoriesClick = type => {
  $amplitude('[Landing] Stories Click', {
    type,
  })
}

/**
 * [Landing] Learn More Button Click
 */

export const eventLearnMoreButtonClick = () => {
  $amplitude('[Landing] Learn More Button Click')
}

/**
 * [Landing] Autoposting How It Works Tab Click
 * @param {string} tabName
 */

export const eventAutopostingHowItWorksTabClick = tabName => {
  $amplitude('[Landing] Autoposting How It Works Tab Click', {
    tabName,
  })
}

/**
 * [General] Questionary About Business Scope Page Visited
 */

export const eventQuestionaryAboutBusinessScopePageVisited = () => {
  $amplitude('[General] Questionary About Business Scope Page Visited')
}

/**
 * [General] Questionary About Business Scope Next Step Button Click
 * @param {string} business_scope
 */

export const eventQuestionaryAboutBusinessScopeNextStepButtonClick = business_scope => {
  $amplitude('[General] Questionary About Business Scope Next Step Button Click', {
    business_scope,
  })
}

/**
 * [Landing] Autoposting Slider Click
 * @param {string} tabName
 */

export const eventAutopostingSliderClick = tabName => {
  $amplitude('[Landing] Autoposting Slider Click', {
    tabName,
  })
}

/**
 * [Landing] Comment Download Button Click
 */

export const eventCommentDownloadButtonClick = () => {
  $amplitude('[Landing] Comment Download Button Click')
}
