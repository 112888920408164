import { track, setUserId, Identify, identify } from '@amplitude/analytics-browser'

export const $amplitudeSetUserId = userId => {
  userId && setUserId(userId)
}

const $amplitude = (event, data) => {
  track(event, data)
}

export const $amplitudeIdentifySetOnce = (event, data) => {
  const identifyEvent = new Identify()
  identifyEvent.setOnce(event, data)
  identify(identifyEvent)
}

export default $amplitude
